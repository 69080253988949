<template>
    <div
        :class="['grid_btn',touch_class]"
        :style="style"
        @touchstart="mouse_down"
        @touchend="mouse_up"
    >
        <span
            class="back_btn"
            :style="back_style"
        >
            <span
                class="btn_content"
                :style="{color:'#'+text_color,...content_style}"
            >
                <mdicon
                    v-if="icon && icon!='none'"
                    :name="icon"
                    size="45px"
                />
                <span
                    v-else-if="!icon"
                    v-html="text"
                />
            </span>
        </span>
    </div>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch, defineProps, defineEmits } from 'vue'

// ------------------------------------------------------ GLOBAL DATA

const emit = defineEmits()

const props = defineProps({
    width: Number,

    cols: Number,
    rows: Number,

    descriptor: String,

    remove: Boolean,

    from: Array,
    to: Array,
})

import { colors } from '../global'

const black_colors_text = ['white', 'transparent']

const color_names = Object.keys(colors)

// ------------------------------------------------------ ACTIONS

const touch_class = ref(null)

const max_touch_time = 500
let timer_start = null
let timer_to = null

function emit_event(name) {
    if (!event.value) return
    console.log(name, ...event.value.split('|'))
    emit(name, ...event.value.split('|'))
}
function emit_long_event(name) {
    if (!long_event.value) return
    console.log(name, ...long_event.value.split('|'))
    emit(name, ...long_event.value.split('|'))
}

function mouse_down() {
    touch_class.value = 'touch'
    timer_start = Date.now()
    timer_to = setTimeout(() => {
        mouse_up()
    }, max_touch_time)
}

function mouse_up() {
    if (!timer_start) return
    touch_class.value = 'untouch'
    const diff = Date.now() - timer_start
    clearTimeout(timer_to)
    timer_start = null
    if (diff >= max_touch_time) emit_long_event('touch')
    else emit_event('touch')
}

// ------------------------------------------------------ UTILS

function touch_id_to_coords(touch_id) {
    if (touch_id == null) return null
    return {
        x: parseInt(touch_id % props.cols),
        y: parseInt(touch_id / props.cols),
    }
}

function coords_to_px(coords) {
    if (!coords) return null
    return {
        x: coords.x * btn_size.value,
        y: coords.y * btn_size.value,
    }
}

function pxs_to_style(pxs, is_to_px = false) {
    const ending_style = {
        transform: 'scale(0.0)',
        opacity: 0.3
    }
    if (!pxs) return ending_style
    return {
        left: pxs[0].x + 'px',
        top: pxs[0].y + 'px',
        right: props.width - (pxs[1].x + btn_size.value) + 'px',
        bottom: grid_height.value - (pxs[1].y + btn_size.value) + 'px',
        ...(is_to_px && props.remove ? ending_style : {})
    }
}

// ------------------------------------------------------ DATA

const content = computed(() => props.descriptor.split(':')[0])

const sub_props = computed(() => {
    const [first, ...sub_props] = props.descriptor.split(':')
    return sub_props
})

const color_name = computed(() => sub_props.value.find(prop => color_names.includes(prop)) ?? 'blue')

const color = computed(() => colors[color_name.value])

const text_color_name = computed(() => sub_props.value.find(prop => prop.includes('&'))?.replace('&', ''))

const text_color = computed(() => colors[text_color_name.value] ?? (black_colors_text.includes(color_name.value) ? '000000' : 'ffffff'))

const text = computed(() => content.value.includes('!') ? null : content.value)
const icon = computed(() => content.value.includes('!') ? content.value.replace('!', '') : null)

const event = computed(() => sub_props.value.find(prop => prop.includes('@') && prop.split('@'.length == 2))?.replace('@', ''))
const long_event = computed(() => sub_props.value.find(prop => prop.includes('@@'))?.replace('@@', ''))

const has_border = computed(() => !!sub_props.value.find(v => v == 'border'))

const sizes = {
    'normal': 1,
    'small': 0.7,
    'xsmall': 0.5,
}
const size = computed(() => {
    const size_name = sub_props.value.find(prop => Object.keys(sizes).includes(prop)) ?? 'normal'
    return sizes[size_name]
})

const animation_timers = {
    'wave_down': 'infinite'
}

const animation_name = computed(() => sub_props.value.find(prop => prop.includes('#'))?.replace('#', ''))
const animation_timer = computed(() => animation_timers[animation_name.value] ?? 'forwards')

// ------------------------------------------------------ STYLE

// ---- base
const btn_size = computed(() => (props.width) / props.cols)
const grid_height = computed(() => (props.width / props.cols) * props.rows)

// ---- style cascade
const from_to_coords = computed(() => [props.from, props.to].map(touch_ids => touch_ids?.map(tid => touch_id_to_coords(tid))))
const from_to_px = computed(() => from_to_coords.value.map(coords_pair => coords_pair?.map(coords => coords_to_px(coords))))
const from_to_styles = computed(() => {
    const [from_px, to_px] = from_to_px.value
    return [
        pxs_to_style(from_px),
        (!to_px ? { ...pxs_to_style(from_px), ...pxs_to_style(to_px, true) } : pxs_to_style(to_px, true))
    ]
})

// ---- animation
const animation_style = computed(() => {
    const scale_timer = 0.2
    const scale_delay = 0 + Math.random() * 0.3
    const scale_transition = `transform ${scale_timer}s ${scale_delay}s, opacity ${scale_timer}s ${scale_delay}s`
    if (!props.from || !props.to) return { transition: scale_transition }
    const [[from_coords], [to_coords]] = from_to_coords.value
    const curve = 'cubic-bezier(0.7, 0, 0.21, 1)'
    const left_base_movers = ['left', 'right']
    const top_base_movers = ['top', 'bottom']
    const left_movers = from_coords.x > to_coords.x ? left_base_movers : left_base_movers.reverse()
    const top_movers = from_coords.y > to_coords.y ? top_base_movers : top_base_movers.reverse()
    const movers = [0, 1].map(id => [top_movers[id], left_movers[id]])
    const rander = Math.random() * 0.1
    const first_timer = { time: 0.3, delay: 0 + rander / 2 }
    const last_timer = { time: 0.4 + rander, delay: 0.01 + rander }
    return {
        transition: [first_timer, last_timer]
            .map((timer, time_i) => movers[time_i]
                .map(mover => `${mover} ${timer.time}s ${timer.delay}s ${curve}`))
            .flat().concat([scale_transition]).join(',')
    }
})

// ---- style selection
const style_id = ref(0)
const style = computed(() => ({
    ...{ transform: `scale(${size.value})` },
    ...from_to_styles.value[style_id.value],
    ...animation_style.value
}))

// ------------------------------------------------------ BACK

const content_style = computed(() => {
    if (!animation_name.value) return
    return {
        animation: animation_name.value + ' 1s 0.2s ' + animation_timer.value
    }
})

const back_style = computed(() => ({
    background: color == 'transparent' ? color.value : '#' + color.value,
    overflow: animation_name.value ? 'hidden' : 'normal',
    border: '1px solid #' + (has_border.value ? text_color.value : null)
}))

onMounted(() => {
    setTimeout(() => {
        style_id.value = 1
    }, 0)
})

</script>

<style>
.grid_btn {
    transform: scale(1);
    position: absolute;
    animation: pop_in 0.2s;
    background: transparent !important;
}
.grid_btn .back_btn {
    position: absolute;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    background: transparent;
    margin: 5px;
    z-index: -1;
    border-radius: 1000px;
}
.btn_content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% - 20px);
    transform: translate(-50%, -50%);
    font-size: 30px;
    color: #fff;
    text-align: center;
    transition: opacity 0.3s;
}

.grid_btn.touch .btn_content {
    transition: opacity 0.05s;
    opacity: 0.5;
}

.grid_btn.untouch .back_btn {
    animation: btn_untouch 0.3s forwards;
}
.grid_btn.touch .back_btn {
    animation: btn_touch 0.05s forwards;
}

@keyframes btn_untouch {
    0% {
        transform: scale(0.9);
        opacity: 0.8;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes btn_touch {
    0% {
        transform: scale(1);
        opacity: 0.8;
    }
    100% {
        transform: scale(0.9);
        opacity: 0.8;
    }
}

/* ------------------------- ANIMATIONS */

@keyframes test {
}

@keyframes wave_down {
    0% {
        top: 40%;
    }
    50% {
        top: 50%;
    }
    100% {
        top: 40%;
    }
}

@keyframes wiggle {
    0% {
        transform: translate(-50%, -50%) rotate(10deg);
    }
    12% {
        transform: translate(-50%, -50%) rotate(-10deg);
    }
    25% {
        transform: translate(-50%, -50%) rotate(20deg);
    }
    37% {
        transform: translate(-50%, -50%) rotate(-5deg);
    }
    50% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
}

@keyframes enter_left {
    0% {
        left: -50%;
    }
    70% {
        left: 50%;
    }
}

@keyframes enter_top {
    0% {
        top: -50%;
    }
    70% {
        top: 50%;
    }
}

@keyframes enter_right {
    0% {
        left: auto;
        right: -50%;
        transform: translate(50%, -50%);
    }
    70% {
        left: auto;
        right: 50%;
        transform: translate(50%, -50%);
    }
}

@keyframes enter_bottom {
    0% {
        top: auto;
        bottom: -50%;
        transform: translate(-50%, 50%);
    }
    70% {
        top: auto;
        bottom: 50%;
        transform: translate(-50%, 50%);
    }
}

@keyframes enter_left_loop {
    0% {
        left: -50%;
    }
    10% {
        left: -50%;
    }
    60% {
        left: 150%;
        opacity: 1;
    }
    61% {
        opacity: 0;
    }
    70% {
        left: -50%;
        opacity: 0;
    }
    71% {
        opacity: 1;
    }
    100% {
        left: 50%;
    }
}
</style>