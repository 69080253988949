<template>
    <div :style="style">
        <div :style="grid_style">
            <grid-btn
                v-for="btn in btns"
                :key="Math.random()"
                v-bind="{...$attrs,...btn}"
            >{{uuid()}}</grid-btn>
        </div>
    </div>
</template>

<script setup>
import GridBtn from './grid-btn.vue'
import { v4 as uuid } from 'uuid'
import { ref, onMounted, reactive, computed, watch, defineProps } from 'vue'

const props = defineProps({
    cols: Number,
    rows: Number,
    width: Number,
    grid_template: Array,
})

const btn_props = computed(() => ({ cols: props.cols, rows: props.rows, width: props.width }))

const true_height = computed(() => (props.width / props.cols) * props.rows)

const style = computed(() => ({
    width: props.width + 'px',
    height: true_height.value + 'px',
    gridTemplateColumns: '1fr '.repeat(props.cols),
    gridTemplateRows: '1fr '.repeat(props.rows),
}))

const grid_style = computed(() => ({
    ...style.value,
    width: (props.width - 10) + 'px',
    height: (true_height.value - 10) + 'px'
}))

function touch_id_to_coords(touch_id) {
    return {
        x: parseInt(touch_id % props.cols),
        y: parseInt(touch_id / props.cols),
    }
}

function coords_to_touch_id(coords) {
    if (coords.x > props.cols - 1 || coords.x < 0) return null
    if (coords.y > props.rows - 1 || coords.y < 0) return null
    return coords.y * props.cols + coords.x
}

function around_touch_ids(touch_id) {
    const coords = touch_id_to_coords(touch_id)
    return [
        [[0, 0]],
        [[-1, 0], [1, 0],
        [0, -1], [0, 1]].sort(() => Math.random()),
    ]
        .flat()
        .map(ac => ({ x: coords.x + ac[0], y: coords.y + ac[1] }))
        .map(coords_to_touch_id)
        .filter(tids => tids != null && tids >= 0)
}

let last_btns = []

const btns = computed(() => {
    const gen_id = uuid().split('-')[0]
    const working_grid = props.grid_template
    const tids = working_grid.map((descriptor, touch_id) => ({ descriptor, touch_id }))
    const used_last_descriptor = []
    const btns = working_grid
        .filter((e, i, s) => e && s.indexOf(e) == i)
        .map(descriptor => {
            const touch_ids = tids
                .filter(({ descriptor: f_descriptor }) => descriptor == f_descriptor)
                .map(({ touch_id }) => touch_id)
            const to = [Math.min(...touch_ids), Math.max(...touch_ids)]
            const coords = to.map(touch_id_to_coords)
            const occ_cols = Array(coords[1].x + 1 - coords[0].x).fill(0).map((_, i) => coords[0].x + i)
            const occ_rows = Array(coords[1].y + 1 - coords[0].y).fill(0).map((_, i) => coords[0].y + i)
            const occupying = occ_cols.map(x => occ_rows.map(y => coords_to_touch_id({ x, y }))).flat()

            const neibooring_tids = occupying.map(tid => around_touch_ids(tid)).flat().filter((e, i, s) => s.indexOf(e) == i)
            let last_neiboor = !last_btns ? null :
                neibooring_tids.map(touch_id => last_btns.find(btn => btn.occupying.includes(touch_id))).filter(e => e)[0]

            if (last_neiboor && last_neiboor.descriptor.split(':')[1] == descriptor.split(':')[1])
                used_last_descriptor.push(last_neiboor?.descriptor)
            else last_neiboor = null

            const sender = {
                width: props.width,
                cols: props.cols,
                rows: props.rows,

                occupying,

                from_descriptor: last_neiboor?.descriptor ?? null,
                descriptor: descriptor,

                from: last_neiboor?.to ?? null,
                to,
            }

            return sender

        })
    const unused_last_btn = last_btns.filter(btn => !used_last_descriptor.includes(btn.descriptor))
    last_btns = JSON.parse(JSON.stringify(btns))
    btns.push(...unused_last_btn.map(btn => ({
        ...btn,
        remove: true,
        from: btn.to,
        to: btn.from,
    })))
    return btns
})

function touch(touch_id) {
    console.log(touch_id_to_coords(touch_id))
}

</script>

<style>
</style>