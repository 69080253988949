import { v4 as uuid } from 'uuid'

export default function (functions) {

    console.log('OS init')

    // ----------------------------------------------------------------------------------------- UTILS FUNCTIONS

    const { set_grid, set_actions, snapshot } = functions

    function set_config(config, act_init = true) {
        const { act, init, grid, actions } = config
        if (act) act()
        else {
            let goone = true
            if (init && act_init) goone = init() !== false
            if (goone) {
                const config_id = ''//uuid()
                const sending_grid = (typeof grid == 'function' ? grid() : grid).flat()
                    .map(elm => elm ? elm.replace(/(@(?:\w|_|-)+)/g, (m, g) => g + config_id) : elm)
                const sending_actions = Object.fromEntries(Object.entries((typeof actions == 'function' ? actions() : actions))
                    .map(([name, func]) => [name + config_id, func]))
                set_grid(sending_grid)
                set_actions(sending_actions)
            }
        }
        return snapshot()
    }

    const row = [undefined, undefined, undefined]
    const null_row = [null, null, null]
    const empty_grid = () => JSON.parse(JSON.stringify(
        [...null_row, ...null_row, ...null_row, ...null_row, ...null_row]))

    function create_btn(...args) {
        return [...args].join(':')
    }

    function create_n_btn(n, ...args) {
        return Array(n).fill(0).map(() => create_btn(...args))
    }

    function create_grid(grid) {
        const ret_grid = empty_grid()
        grid.forEach((d, i) => ret_grid[i] = d)
        return ret_grid
    }

    function single_exec_config(grid, actions) {
        set_grid(grid)
        let end = null
        actions = Object.fromEntries(([name, func]) => [
            name,
            async (...args) => {
                end(await func(...args))
            }
        ])
        return new Promise(ok => end = ok)
    }

    function multi_config_display(configs_funcs) {

        let current_config_name = null
        const history = []

        function goto_full(config_name, initer, ...args) {
            current_config_name = config_name
            // let snapshot = []
            // function reset() {
            //     set_config(snapshot)
            // }
            const redo = () => goto_no_init(config_name, ...args)
            const config = typeof configs_funcs[current_config_name] == 'function' ?
                configs_funcs[current_config_name]({ ...function_package, redo }, ...args)
                :
                configs_funcs[current_config_name]
            // snapshot = set_config(config, initer)
            set_config(config, initer)
            // configs_funcs[current_config_name] = snapshot
            history.push(config_name)
        }

        function goto(config_name, ...args) {
            goto_full(config_name, true, ...args)
        }

        function goto_no_init(config_name, ...args) {
            goto_full(config_name, false, ...args)
        }

        function back(...args) {
            history.pop()
            const back_index = history.pop()
            goto(back_index, ...args)
        }

        function redo_w_init(...args) {
            goto(current_config_name, ...args)
        }

        let end = null
        const ret_prom = new Promise(ok => end = ok)

        const function_package = { goto, back, redo_w_init, end }

        goto('main')

        return ret_prom

    }

    async function load_program(name, ...args) {
        console.log('loading program', name)
        const program = (await import('./Programs/' + name + '.js')).default
        return multi_config_display(program(OS_functs(), ...args))
    }

    async function load_multi_programs(names, ...args) {
        return Promise.all(names.map(name => load_program(name, ...args)))
    }

    const OS_functs = () => ({
        set_config, empty_grid, row, null_row, create_btn, create_n_btn,
        create_grid, set_grid, single_exec_config, multi_config_display,
        load_program, load_multi_programs
    })

    // ----------------------------------------------------------------------------------------- FUNCTIONS

    set_config({ grid: empty_grid(), actions: {} })
    load_program('boot')

}