export const colors = {
    grey: 'aaaaaa',
    blue: '0077ff',
    red: 'ff2b14',
    green: '00af40',
    yellow: 'ffc800',
    black: '121212',
    brown: '934e4e',
    purple: 'aa21bf',
    white: '#ffffff',
}

export const text_colors = {
    white: Object.keys(colors).filter(c => c != 'white')
}

export const icons = [
    'leaf', 'home', 'phone',
    'door', 'lightbulb', 'account',
    'plus', 'minus', 'close',
    'fan'
]