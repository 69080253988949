<template>
    <grid
        class='main_grid'
        style="background:#fff"
        :cols="3"
        :rows="5"
        :width="width"
        :grid_template="grid_template"
        @touch="touch"
        @long_touch="long_touch"
    ></grid>

</template>

<script setup>
import Grid from './components/grid.vue'
import { ref, onMounted, reactive, computed, watch } from 'vue'
import OS from './OS'

const grid_template = reactive([
    null, null, null,
    null, null, null,
    null, null, null,
    null, null, null,
    null, null, null,
])

const actions_template = reactive({
})

function touch_act(name, args, is_long) {
    const action = actions_template[name]
    if (!action) return
    if (typeof action == 'function') action(...args)
    else if (is_long) action.long(...args)
    else action.touch(...args)
}

function touch() {
    const all_args = Array.from(arguments)
    const [name, ...args] = all_args
    touch_act(name, args, false)
}

function long_touch() {
    const all_args = Array.from(arguments)
    const [name, ...args] = all_args
    touch_act(name, args, true)
}

function set_grid(grid) {
    grid.forEach((btn, i) => btn !== undefined ? grid_template[i] = btn : null)
}

const action_matcher = /(?:@@|@)((?:\w|-)+):?/g

function find_descriptor_actions(descriptor) {
    if (!descriptor) return []
    return descriptor.split(':').filter(elm => elm.includes('@')).map(elm => elm.replace(/@/g, '').split('|')[0])
}

function has_actions(descriptor) {
    return descriptor && descriptor.match(action_matcher)
}

function set_actions(actions) {
    const all_grid_actions = grid_template.filter(has_actions).map(find_descriptor_actions).flat()
    all_grid_actions.forEach(act_name => actions_template[act_name] = actions[act_name] ?? actions_template[act_name])
}

function snapshot() {
    return {
        grid: JSON.parse(JSON.stringify(grid_template)),
        actions: { ...actions_template },
    }
}

const width = ref(0)

onMounted(() => {
    width.value = window.innerWidth
    set_grid([
        null, null, null,
        null, null, null,
        'no OS init:white', 'no OS init:white', 'no OS init:white'
    ])
    OS({ set_grid, set_actions, snapshot })
})

</script>


<style>
body {
    margin: 0px;
    font-family: Roboto;
    background: #000;
    user-select: none;
}
.main_grid {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
}
</style>
